<template>
  <div class="personalInformation md-layout-item md-medium-size-50 md-small-size-100">

    <form novalidate @submit.prevent="validateUserInput">

      <h2 class="md-headline">Persönliche Information</h2>

      <md-field :class="{'md-invalid': $v.client.name.$error}">
        <label for="input-name">Name</label>
        <md-input name="input-name" id="input-name" autocomplete="given-name" v-model.trim="$v.client.name.$model" @blur="addName(); validationPers()" type="text"></md-input>
        <span v-if="!$v.client.name.required" class="md-error">*Name ist erforderlich</span>
        <span v-else-if="!$v.client.name.minLength" class="md-error">min. {{ $v.client.name.$params.minLength.min}} Zeichen</span>
        <span v-else-if="!$v.client.name.maxLength" class="md-error">max. {{ $v.client.name.$params.maxLength.max}} Zeichen</span>
        <md-icon v-if="$v.client.name.required && $v.client.name.minLength && $v.client.name.maxLength">check</md-icon>
        <span v-else class="md-helper-text">Bsp. John Doe</span>
      </md-field>

      <md-field :class="{'md-invalid': $v.client.email.$error}">
        <label for="input-email">Email</label>
        <md-input name="input-email" id="input-email" autocomplete="email" v-model.trim="$v.client.email.$model" @blur="addEmail(); validationPers()" type="email"></md-input>
        <span v-if="!$v.client.email.required" class="md-error">*Email ist erforderlich</span>
        <span v-else-if="!$v.client.email.email" class="md-error">Ungültige Email-Adresse</span>
        <md-icon v-if="$v.client.email.required && $v.client.email.email">check</md-icon>
        <span v-else class="md-helper-text">Bsp. John.Doe@mail.com</span>
      </md-field>

      <md-field :class="{'md-invalid': $v.client.phoneNr.$error}">
        <label for="input-phoneNr">Telefonnummer</label>
        <md-input name="input-phoneNr" id="input-phoneNr" autocomplete="tel" v-model.trim="$v.client.phoneNr.$model" @blur="addPhoneNr(); validationPers()" type="text"></md-input>
        <span v-if="!$v.client.phoneNr.required" class="md-error">*Telefonnummer ist erforderlich</span>
        <span v-else-if="!$v.client.phoneNr.phoneValid" class="md-error">Format: +41 81 123 45 67</span>
        <md-icon v-if="$v.client.phoneNr.required && $v.client.phoneNr.phoneValid">check</md-icon>
        <span v-else class="md-helper-text">Bsp. +41 81 123 45 67</span>
      </md-field>
    </form>

  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  helpers
} from 'vuelidate/lib/validators'
const phoneValid = helpers.regex('phoneValid', /\+[0-9]{2} [0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}/)

export default {
  name: "personal-information",

  data: () => ({
    client: {
      name: '',
      email: '',
      phoneNr: '',
      valideName: false,
      valideEmail: false,
      validePhone: false
    },
    nameValidClass: 'md-valid'
  }),

  props: {},

  validations: {
    client: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      email: {
        required,
        email
      },
      phoneNr: {
        required,
        phoneValid
      }
    }
  },

  methods: {
    addName(){
      let invalidName = this.$v.client.name.$error
      if(!invalidName && this.client.name !== ''){
        this.$store.dispatch("order/updateName", this.client.name)
        this.valideName = true
      }else {
        this.valideName = false
      }
    },
    addEmail(){
      let invalidEmail = this.$v.client.email.$error
      if (!invalidEmail && this.client.email !== ''){
        this.$store.dispatch("order/updateEmail", this.client.email)
        this.validEmail = true
      }else {
        this.validEmail = false
      }
    },
    addPhoneNr(){
      let invalidPhone = this.$v.client.phoneNr.$error
      if(!invalidPhone && this.client.phoneNr !== ''){
        this.$store.dispatch("order/updateTelefon", this.client.phoneNr)
        this.validPhone = true
      }else {
        this.validPhone = false
      }
    },
    validationPers(){
      if (this.valideName && this.validEmail && this.validPhone) {
        const validationState = true
        this.$store.dispatch("order/updateValidationPersonalInformation", validationState)
      }else {
        const validationState = false
        this.$store.dispatch("order/updateValidationPersonalInformation", validationState)
      }
      this.$store.dispatch("order/validateInputs")
    }
  }
}
</script>