<template>
  <div class="page-container">
    <md-app md-mode="reveal">
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <h1 class="md-title">Auftrag bestätigt</h1>
      </md-app-toolbar>

      <!-- App-Drawer - Navigation -->
      <md-app-drawer :md-active.sync="menuVisible">
        <md-toolbar class="md-transparent" md-elevation="0">Navigation</md-toolbar>
        <md-list>
          <md-list-item>
            <md-icon>confirmation_number</md-icon>
            <p class="md-list-item-text">
              <router-link to="/new/YsCVjhVqaww3PLKDxSQN">Zur App - Sarkis</router-link>
            </p>
          </md-list-item>
          <md-list-item>
            <md-icon>shopping_bag</md-icon>
            <p class="md-list-item-text" style="text-decoration: none">
              <router-link to="/new/spE23R8GeleMlGYsGwH4">Zur App - Test</router-link>
            </p>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <!-- App Content -->
      <md-app-content>
        <div class="md-layout md-gutter">
          <div class="confirm md-layout-item">
            <div style="text-align: center">
              <img :src="companyLogo" style="max-width: 50vw">
              <p class="md-caption" style="font-size: x-large; text-align: center;">Vielen Dank für Ihren Auftrag!</p>
            </div>
            <br>
            <p class="md-caption" style="font-size: larger">
              Es freut uns sehr, den Auftrag #291 entgegen genommen zu haben. Sie sollten zudem in wenigen Minuten eine Auftragsbestätigung in ihrem E-Mail Postfach finden.
            </p>
            <h2 class="md-headline">Neuer Auftrag erfassen</h2>
            <p class="md-caption" style="font-size: larger">
              Haben Sie bereits ein neuer Auftrag für uns? Gerne können Sie diesen hier erfassen:
            </p>
            <router-link :to="`/new/${companyId}`">
              <md-button class="md-raised md-primary" style="text-transform: uppercase;">Neuer Auftrag</md-button>
            </router-link>
          </div>
          <br>
        </div>
      </md-app-content>

    </md-app>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderConfirmation",

  props: {
    companyId: {
      type: String
    }
  },

  data: () => ({
    menuVisible: false,
  }),

  computed : {
    ...mapGetters({
      companyLogo: 'companies/companyLogo'
    }),
  }
}
</script>