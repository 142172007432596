<template>
  <div class="nav">
    <ul>
      <li>
        <router-link to="/new/YsCVjhVqaww3PLKDxSQN">Zur App - Sarkis</router-link>
      </li>
      <li>
        <router-link to="/new/spE23R8GeleMlGYsGwH4">Zur App - Wäscherei Test</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
name: "Home"
}
</script>
