import * as fb from "../../firebase";
import router from "@/router";

/* ========== STATE ========== */
const state = () => ({
    name: "",
    email: "",
    telefon: null,
    clothes: [],
    company: null,
    comment: "",
    payment: {
        method: '',
        cardNumber: null,
        expireDate: null,
        cvv: null,
        country: '',
        postcode: null,
        place: '',
        street: ''
    },
    totalPrice: null,
    validationPersonalInformation: false,
    validationPaymentOptions: false,
    validationInputs: false
});

/* ========== GETTERS ========== */
const getters = {
    getOrderedClothes: state => {
        return state.clothes
    },
    getOrderedClothingById: (state) => (id) => {
        return state.clothes.find(clothes => clothes.id === id)
    },
    getTotalPrice: (state) => {
        return state.totalPrice
    },
    getValidationInputs: (state) => {
        return state.validationInputs
    }
};

/* ========== ACTIONS ========== */
const actions = {
    updateClothes({ commit },clothing) {
        commit('UPDATE_CLOTHES', clothing)
    },
    removeClothing({ commit }, clothing) {
        commit('REMOVE_CLOTHING', clothing)
    },
    updateComment({ commit }, comment) {
        commit('UPDATE_COMMENT', comment)
    },
    updateName({ commit }, name) {
        commit('UPDATE_NAME', name)
    },
    updateEmail({ commit }, email) {
        commit('UPDATE_EMAIL', email)
    },
    updateTelefon({ commit }, telefon) {
        commit('UPDATE_TELEFON', telefon)
    },
    updateCompany({ commit }, companyNr) {
        commit('UPDATE_COMPANY', companyNr)
    },
    updateMethod({ commit }, paymentMethod) {
        commit('UPDATE_PAYMENT_METHOD', paymentMethod)
    },
    updateCardNumber({ commit }, cardNumber) {
        commit('UPDATE_CARD_NUMBER', cardNumber)
    },
    updateExpireDate({ commit }, expireDate) {
        commit('UPDATE_EXPIRE_DATE', expireDate)
    },
    updateCVV({ commit }, cvv) {
        commit('UPDATE_CVV', cvv)
    },
    updateCountry({ commit }, country) {
        commit('UPDATE_COUNTRY', country)
    },
    updatePostcode({ commit }, postcode) {
        commit('UPDATE_POSTCODE', postcode)
    },
    updatePlace({ commit }, place) {
        commit('UPDATE_PLACE', place)
    },
    updateStreet({ commit }, street) {
        commit('UPDATE_STREET', street)
    },
    updateTotalPrice({ commit }, totalPrice) {
        commit('UPDATE_TOTAL_PRICE', totalPrice)
    },
    updateValidationPersonalInformation({ commit }, validationState) {
        commit('UPDATE_VALIDATION_PERSONAL_INFORMATION', validationState)
    },
    updateValidationPaymentOptions({ commit }, validationState) {
        commit('UPDATE_VALIDATION_PAYMENT_OPTIONS', validationState)
    },
    validateInputs({ commit }) {
      commit('UPDATE_VALIDATION_INPUTS')
    },

    async uploadOrder({ state }, companyID){
        const clothes = state.clothes.map(clothing => {
            return {
                clothesId: clothing.id,
                count: parseInt(clothing.count)
            }
        })
        if (state.payment.method === 'monthly_invoice'){
            var payment = ''
            payment = {
                method: state.payment.method,
                address: [{
                    postcode: state.payment.postcode,
                    place: state.payment.place,
                    street: state.payment.street,
                }],
            }
        } else if(state.payment.method === 'creditcard'){
            payment = state.payment.method
        }
        await fb.orderCollection.add({
            state: false,
            clothes: clothes,
            company: state.company,
            comment: state.comment,
            email: state.email,
            name: state.name,
            telefon: state.telefon,
            payment: payment
        })

         .then((docRef) => {
            console.log('New order has been sent: ', docRef.id);
            router.push(`/confirm/${(companyID)}`)
             console.log(companyID)
        })
        .catch((error) => {
            console.error('Error while sending new order; ', error);
        })
    },
};

/* ========== MUTATIONS ========== */
const mutations = {
    UPDATE_CLOTHES (state, clothing) {
        if(state.clothes.includes(clothing) === true){
            const objIndex = state.clothes.findIndex(obj =>obj.id === clothing.id)
            state.clothes.splice(objIndex, 1, clothing)
            console.log('Updating ' + clothing.type + ' ...')
        } else {
            state.clothes.push(clothing)
            console.log('Adding ' + clothing.type + ' ...')
        }
    },

    REMOVE_CLOTHING (state, clothing) {
        const objIndex = state.clothes.findIndex(obj =>obj.id === clothing.id)
        state.clothes.splice(objIndex, 1)
        console.log('Removing ' + clothing.type + ' ...')
    },

    UPDATE_COMMENT (state, comment) {
        state.comment = comment
    },

    UPDATE_NAME (state, name) {
        state.name = name
    },

    UPDATE_EMAIL (state, email) {
        state.email = email
    },

    UPDATE_TELEFON (state, telefon) {
        state.telefon = telefon
    },

    UPDATE_COMPANY (state, company) {
        state.company = company
    },
    UPDATE_CARD_NUMBER (state, cardNumber) {
        state.payment.cardNumber = cardNumber
    },
    UPDATE_EXPIRE_DATE (state, expireDate) {
        state.payment.expireDate = expireDate
    },
    UPDATE_PAYMENT_METHOD (state, paymentMethod) {
        state.payment.method = paymentMethod
    },
    UPDATE_CVV (state, cvv) {
        state.payment.cvv = cvv
    },
    UPDATE_COUNTRY (state, country) {
        state.payment.country = country
    },
    UPDATE_POSTCODE (state, postcode) {
        state.payment.postcode = postcode
    },
    UPDATE_PLACE (state, place) {
        state.payment.place = place
    },
    UPDATE_STREET (state, street) {
        state.payment.street = street
    },
    UPDATE_TOTAL_PRICE (state, price) {
        state.totalPrice = price
    },
    UPDATE_VALIDATION_PERSONAL_INFORMATION (state, validationState) {
        state.validationPersonalInformation = validationState
    },
    UPDATE_VALIDATION_PAYMENT_OPTIONS (state, validationState) {
        state.validationPaymentOptions = validationState
    },
    UPDATE_VALIDATION_INPUTS (state) {
        if (state.validationPersonalInformation && state.validationPaymentOptions && state.company && state.clothes.length){
            state.validationInputs = true
        } else {
            state.validationInputs = false
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
