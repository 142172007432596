import * as fb from "../../firebase";

/* ========== STATE ========== */
const state = {
    clothes: []
};
/* ========== GETTERS ========== */
const getters = {
  allClothes: state => {
    return state.clothes
  },
  getClothing: (state) => (clothingID) => {
    return state.clothes.find(clothing => clothing.id === clothingID)
  }
};

/* ========== ACTIONS ========== */
const actions = {
  async getClothes({ commit }) {
    commit('DELETE_CLOTHES')
    fb.clothesCollection.onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        let clothing = doc.data()
        clothing.id = doc.id

        /* Get Icon URL */
        const imageRef = fb.storage.ref().child('icons/' + clothing.image);
        imageRef.getDownloadURL()
        .then((response) => {
          clothing.icon = response
          commit('SET_CLOTHES', clothing)
        }).catch(() => {
          console.log("Fehler beim Laden des Bildes")
        })
      })
    });
  }
};

/* ========== MUTATIONS ========== */
const mutations = {
  SET_CLOTHES(state, clothes) {
    state.clothes.push(clothes)
  },
  DELETE_CLOTHES(state) {
    state.clothes = []
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
