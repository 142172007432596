<template>
  <div class="page-container">
    <md-app md-mode="reveal">

      <!-- App Toolbar -->
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <h1 class="md-title">Login zur Adminseite</h1>
      </md-app-toolbar>

      <md-app-content>
        <div class="md-layout md-gutter md-alignment-center">
          <div class="info md-layout-item md-size-50">
            <div class="container text-center" v-if="!loggedIn">
              <h2>Geben Sie das Passwort ein.</h2>
              <form v-on:submit.prevent="onSubmit">
                <md-field>
                  <label>Passwort</label>
                  <md-input type="password" name="password" v-model.trim="password"></md-input>
                </md-field>
                <div v-if="error"><p>Das eingegebene Passwort ist falsch!</p></div>
                <md-button class="md-accent" type="submit">Submit</md-button>
              </form>
            </div>
            <div v-else>
              <h2>Sie sind zur Zeit angemeldet</h2>
              <md-button class="md-accent" @click="logout">Logout</md-button>
            </div>
          </div>
        </div>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>

export default {
  name: "Protected",
  data () {
    return {
      error: null,
      password: null,
      loggedIn: localStorage.password || false
    }
  },
  methods: {
    onSubmit () {
      if(this.password === 'HeloWorld') {
        localStorage.password = 'HeloWorld'
        this.$router.push('/admin')
      } else {
        this.error = true
      }
    },
    logout () {
      localStorage.password = ''
      window.location.reload();
    }
  }
}
</script>

<style scoped>

</style>