import * as fb from "../../firebase";

/* ========== STATE ========== */
const state = {
    allOrders: [],
    allClothes: []
};
/* ========== GETTERS ========== */
const getters = {
    getOrders: state => {
        return state.allOrders
    },
    getClothing: (state) => (clothingID) => {
        return state.allClothes.find(clothing => clothing.id === clothingID)
    }
};

/* ========== ACTIONS ========== */
const actions = {
    async getOrders({ commit }) {
        fb.orderCollection
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    commit('SET_ORDERS', {
                        ...doc.data(),
                        id: doc.id
                    })
                })
            })
    },
    async getClothes({ commit }) {
        fb.clothesCollection.onSnapshot(snapshot => {
            snapshot.forEach(doc => {
                let clothing = doc.data()
                clothing.id = doc.id

                /* Get Icon URL */
                const imageRef = fb.storage.ref().child('icons/' + clothing.image);
                imageRef.getDownloadURL()
                    .then((response) => {
                        clothing.icon = response
                        commit('SET_CLOTHES', clothing)
                    }).catch(() => {
                    console.log("Fehler beim Laden des Bildes")
                })
            })
        });
    },
    updateOrderStatus({commit}, { orderId, status } ) {
        commit('UPDATE_STATE', { orderId, status } )
        fb.orderCollection
            .doc(orderId)
            .update({ state: status })
            .then(() => {
                console.log('Status updated')
            }).catch(e => {
            console.log(e)
        })
    }
};

/* ========== MUTATIONS ========== */
const mutations = {
    SET_ORDERS(state, orders) {
        state.allOrders.push(orders)
    },
    SET_CLOTHES(state, clothes) {
        state.allClothes.push(clothes)
    },
    UPDATE_STATE(state, { orderId, status}) {
        state.allOrders.find(itm => itm.id === orderId).state = status
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
