<template>
  <div class="payment md-layout-item md-size-100">
    <h2 class="md-headline">Zahlungsmethoden</h2>
    <p class="md-caption" style="font-size: larger">Weitere Zahlungsmethoden werden folgen.</p>

    <!-- Redio Button - Payment-Method -->
    <p class="md-headline" style="font-size: large">
      <md-radio class="md-primary" v-model="order.payment.method" value="creditcard">Kreditkarte</md-radio>
      <md-radio class="md-primary" v-model="order.payment.method" value="twint" disabled>Twint</md-radio>
      <md-radio class="md-primary" v-model="order.payment.method" value="monthly_invoice">Monatliche Rechnung</md-radio>
    </p>

    <!-- CreditCard - CardNumber -->
    <form v-if="this.order.payment.method === 'creditcard'" novalidate @submit.prevent="validatePaymentInput">
      <md-field :class="{'md-invalid': !inputCardNumberValid}">
        <label for="input-card-number">Kreditkartennummer</label>
        <md-input name="input-card-number" v-cardformat:formatCardNumber id="input-card-number" type="tel" autocomplete="cc-number" @keyup="validateNumber(); validationPay()" @blur="validateNumber(); validationPay()" v-model.trim="$v.order.payment.cardNumber.$model"></md-input>
        <span v-if="!$v.order.payment.cardNumber.required" class="md-error">*Kreditkartennummer ist erforderlich</span>
        <span v-else-if="cardErrors.cardNumber" class="md-error">Ungültige Kreditkartennummer</span>
        <md-icon v-if="$v.order.payment.cardNumber.required && !cardErrors.cardNumber">check</md-icon>
        <span v-else class="md-helper-text">Bsp. 5234 0012 3456 7890 </span>
      </md-field>

      <!-- CreditCard - ExpireDate -->
      <md-field :class="{'md-invalid': !inputExpireDateValid}">
        <label for="input-expire-date">Monat / Jahr</label>
        <md-input name="input-expire-date" id="input-expire-date" type="tel" autocomplete="cc-exp" v-cardformat:formatCardExpiry @keyup="validateExpireDate(); validationPay()" @blur="validateExpireDate(); validationPay();" v-model.trim="$v.order.payment.expireDate.$model"></md-input>
        <span v-if="!$v.order.payment.expireDate.required" class="md-error">*Ablaufdatum ist erforderlich</span>
        <span v-else-if="cardErrors.expireDate" class="md-error">Format: MM / JJ(JJ)</span>
        <md-icon v-if="$v.order.payment.expireDate.required && !cardErrors.expireDate">check</md-icon>
        <span v-else class="md-helper-text">Bsp. 01 / 2023 </span>
      </md-field>

      <!-- CreditCard - CVV -->
      <md-field :class="{'md-invalid': !inputCVVValid}">
        <label for="input-cvv">CVV</label>
        <md-input name="input-cvv" id="input-cvv" type="tel" v-cardformat:formatCardCVC @keyup="validateCVV(); validationPay()" @blur="validateCVV(); validationPay()" v-model.trim="$v.order.payment.cvv.$model"></md-input>
        <span v-if="!$v.order.payment.cvv.required" class="md-error">*CVV ist erforderlich</span>
        <span v-else-if="cardErrors.cvv" class="md-error">Format: 123(4)</span>
        <md-icon v-if="$v.order.payment.cvv.required && !cardErrors.cvv">check</md-icon>
        <span v-else class="md-helper-text">Bsp. 123 </span>
      </md-field>
    </form>

    <!-- MonthlyInvoice - Country -->
    <form v-if="this.order.payment.method === 'monthly_invoice'">
      <div>
        <md-field>
          <label>Land</label>
          <md-input v-model="order.payment.invoice_country" disabled></md-input>
        </md-field>

        <!-- MonthlyInvoice - Postcode -->
        <md-field :class="{'md-invalid': !inputInvoicePostcodeValid}">
          <label for="input-invoice-postcode">PLZ</label>
          <md-input name="input-invoice-postcode" id="input-invoice-postcode" type="number" autocomplete="postal-code" @keyup="validatePostcode(); validationPay()" @blur="validatePostcode(); validationPay()" v-model.trim="$v.order.payment.invoice_postcode.$model"></md-input>
          <span v-if="!$v.order.payment.invoice_postcode.required" class="md-error">*Postleitzahl ist erforderlich</span>
          <span v-else-if="!validInvoicePostcode" class="md-error">Ungültige Postleitzahl</span>
          <md-icon v-if="$v.order.payment.invoice_postcode.required && validInvoicePostcode">check</md-icon>
          <span v-else class="md-helper-text">Bsp. 7000</span>
        </md-field>

        <!-- MonthlyInvoice - Place -->
        <md-field :class="{'md-invalid': !inputInvoicePlaceValid}">
          <label for="input-invoice-place">Ort</label>
          <md-input name="input-invoice-place" id="input-invoice-place" type="text" autocomplete="locality" @keyup="validatePlace(); validationPay()" @blur="validatePlace(); validationPay()" v-model.trim="$v.order.payment.invoice_place.$model"></md-input>
          <span v-if="!$v.order.payment.invoice_place.required" class="md-error">*Postleitzahl ist erforderlich</span>
          <span v-else-if="!$v.order.payment.invoice_place.maxLength || !$v.order.payment.invoice_place.minLength" class="md-error">Ungültiger Ortschaftsname</span>
          <md-icon v-if="$v.order.payment.invoice_place.required && $v.order.payment.invoice_place.maxLength && $v.order.payment.invoice_place.minLength">check</md-icon>
          <span v-else class="md-helper-text">Bsp. Chur</span>
        </md-field>

        <!-- MonthlyInvoice - Street -->
        <md-field :class="{'md-invalid': !inputInvoiceStreetValid}">
          <label for="input-invoice-steet">Strasse</label>
          <md-input name="input-invoice-steet" id="input-invoice-steet" type="text" autocomplete="street-steet" @keyup="validateStreet(); validationPay()" @blur="validateStreet(); validationPay()" v-model.trim="$v.order.payment.invoice_street.$model"></md-input>
          <span v-if="!$v.order.payment.invoice_street.required" class="md-error">*Strasse ist erforderlich</span>
          <span v-else-if="!$v.order.payment.invoice_street.minLength" class="md-error">min. 5 Zeichen</span>
          <md-icon v-if="$v.order.payment.invoice_street.required && $v.order.payment.invoice_street.minLength">check</md-icon>
          <span v-else class="md-helper-text">Bsp. Bahnhofstrasse 1</span>
        </md-field>
      </div>
    </form>

    <span v-if="validationInputs">
      <p v-if="this.order.payment.method === 'creditcard'" class="md-caption" style="font-size: larger">Mit dem Klick auf den folgenden Button wird Ihnen {{totalPrice}} CHF von der Kreditkarte abgebucht.</p>
      <p v-if="this.order.payment.method === 'monthly_invoice'" class="md-caption" style="font-size: larger">Mit dem Klick auf den folgenden Button wird Ihnen {{totalPrice}} CHF in Rechnung gestellt.</p>
    </span>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  helpers
} from 'vuelidate/lib/validators'
import {mapGetters} from "vuex";
const ccNumberValid = helpers.regex('ccNumberValid', /\[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/ )
export default {
  name: "payment-options",
  data: () => ({
    cardErrors: {},
    inputCardNumberValid: true,
    validCardNumber: false,
    inputExpireDateValid: true,
    validExpireDate: false,
    inputCVVValid: true,
    validCVV: false,
    inputInvoicePostcodeValid: true,
    validInvoicePostcode: false,
    inputInvoicePlaceValid: true,
    validInvoicePLace: false,
    inputInvoiceStreetValid: true,
    validInvoiceStreet: false,
    order: {
      payment: {
        method: 'monthly_invoice',
        cardNumber: '',
        expireDate: '',
        cvv: '',
        invoice_country: 'Schweiz',
        invoice_postcode: null,
        invoice_place: '',
        invoice_street: ''
      }
    },
  }),

  validations: {
    order: {
      payment: {
        cardNumber: {
          required,
          ccNumberValid
        },
        expireDate: {
          required,
          maxLength: maxLength(10)
        },
        cvv: {
          required
        },
        invoice_postcode: {
          required,
        },
        invoice_place: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(35)
        },
        invoice_street: {
          required,
          minLength: minLength(5)
        }
      },
    }
  },

  methods: {
    validateNumber(){
      this.cardErrors.cardNumber = false
      if(
          !this.$cardFormat.validateCardNumber(this.order.payment.cardNumber) ||
          !this.$v.order.payment.cardNumber.required ||
          this.order.payment.cardNumber === ''
      ){
        if(!this.$cardFormat.validateCardNumber(this.order.payment.cardNumber)){
          this.cardErrors.cardNumber = true
        }
        this.inputCardNumberValid = false
        this.validCardNumber = false
      } else {
        this.inputCardNumberValid = true
        this.validCardNumber = true
        this.$store.dispatch("order/updateCardNumber", this.order.payment.cardNumber)
      }
    },
    validateExpireDate(){
      this.cardErrors.expireDate = false
      if(
          !this.$cardFormat.validateCardExpiry(this.order.payment.expireDate) ||
          !this.$v.order.payment.expireDate.required ||
          this.order.payment.expireDate === ''
      ){
        if(!this.$cardFormat.validateCardExpiry(this.order.payment.expireDate)){
          this.cardErrors.expireDate = true
        }
        this.inputExpireDateValid = false
        this.validExpireDate = false
      } else {
        this.inputExpireDateValid = true
        this.validExpireDate = true
        this.$store.dispatch("order/updateExpireDate", this.order.payment.expireDate)
      }
    },
    validateCVV(){
      this.cardErrors.cvv = false
      if(
          !this.$cardFormat.validateCardCVC(this.order.payment.cvv) ||
          !this.$v.order.payment.cvv.required ||
          this.order.payment.cvv === ''
      ){
        if(!this.$cardFormat.validateCardCVC(this.order.payment.cvv)){
          this.cardErrors.cvv = true
        }
        this.inputCVVValid = false
        this.validCVV = false
      } else {
        this.inputCVVValid = true
        this.validCVV = true
        this.$store.dispatch("order/updateCVV", this.order.payment.cvv)
      }
    },
    validatePostcode(){
      if(this.$v.order.payment.invoice_postcode.required){
        if(this.order.payment.invoice_postcode.length === 4 && this.order.payment.invoice_postcode >= 1000){
          this.validInvoicePostcode = true
          this.inputInvoicePostcodeValid = true
          this.$store.dispatch("order/updatePostcode", this.order.payment.invoice_postcode)
        } else{
          this.inputInvoicePostcodeValid = false
          this.validInvoicePostcode = false
        }
      } else {
        this.inputInvoicePostcodeValid = false
      }
    },
    validatePlace(){
      if(this.$v.order.payment.invoice_place.required){
        if(this.$v.order.payment.invoice_place.minLength && this.$v.order.payment.invoice_place.maxLength){
          this.validInvoicePLace = true
          this.inputInvoicePlaceValid = true
          this.$store.dispatch("order/updatePlace", this.order.payment.invoice_place)
        } else{
          this.inputInvoicePlaceValid = false
          this.validInvoicePLace = false
        }
      } else {
        this.inputInvoicePlaceValid = false
      }
    },
    validateStreet(){
      if(this.$v.order.payment.invoice_street.required){
        if(this.$v.order.payment.invoice_street.minLength){
          this.validInvoiceStreet = true
          this.inputInvoiceStreetValid = true
          this.$store.dispatch("order/updateStreet", this.order.payment.invoice_street)
        } else{
          this.inputInvoiceStreetValid = false
          this.validInvoiceStreet = false
        }
      } else {
        this.inputInvoiceStreetValid = false
      }
    },
    validationPay(){
      if (this.order.payment.method === 'creditcard'){
        if(this.validCardNumber && this.validExpireDate && this.validCVV){
          const validationState = true
          this.$store.dispatch("order/updateMethod", this.order.payment.method)
          this.$store.dispatch("order/updateValidationPaymentOptions", validationState)
        }else {
          const validationState = false
          this.$store.dispatch("order/updateValidationPaymentOptions", validationState)
        }
        this.$store.dispatch("order/validateInputs")
      } else if(this.order.payment.method === 'monthly_invoice'){
          if(this.validInvoicePostcode && this.validInvoicePLace && this.validInvoiceStreet){
            const validationState = true
            this.$store.dispatch("order/updateMethod", this.order.payment.method)
            this.$store.dispatch("order/updateCountry", this.order.payment.invoice_country)
            this.$store.dispatch("order/updateValidationPaymentOptions", validationState)
          }else {
            const validationState = false
            this.$store.dispatch("order/updateValidationPaymentOptions", validationState)
          }
          this.$store.dispatch("order/validateInputs")
      }
    },
  },

  computed : {
    ...mapGetters({
      totalPrice: 'order/getTotalPrice',
      validationInputs: 'order/getValidationInputs'
    }),
  },
}

// stripe Testkey pk_test_obEAN0Oz9w57PLwXvItZFKq900F3k6RqgZ
</script>

<style lang="scss" scoped>
  /* Don't show Arrows on input Type Number */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .md-disabled {
    color: rgba(0, 0, 0, 0.54);
  }

  .md-input[disabled=disabled] {
    color: rgba(0, 0, 0, 0.7) !important;
  }
</style>