import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

/* Firebase Config */
const firebaseConfig = {
    apiKey: 'AIzaSyA8_fqZDb9AkXFfMmWIzcftoNID6D9iBis',
    authDomain: 'washmyclothes-ch.firebaseapp.com',
    projectId: 'washmyclothes-ch',
    storageBucket: 'washmyclothes-ch.appspot.com',
    messagingSenderId: '664725213195',
    appId: '1:664725213195:web:4f71f5789a416d78fb770e',
    measurementId: 'G-GBW6VPW9MW'
}
firebase.initializeApp(firebaseConfig)

/* Utils */
const db = firebase.firestore();
const storage = firebase.storage();

/* Collection References */
const clothesCollection = db.collection('clothes');
const companiesCollection = db.collection('companies');
const orderCollection = db.collection('order');

export {
    db,
    clothesCollection,
    companiesCollection,
    orderCollection,
    storage
}