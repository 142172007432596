<template>
  <div class="summery md-layout-item md-size-100">
    <h2 class="md-headline">Zusammenfassung</h2>

    <!-- Table - Order Summary -->
    <md-table v-if="orderedClothes.length">
      <md-table-toolbar>
        <h3 class="md-title">Ihre Bestellung</h3>
      </md-table-toolbar>
      <md-table-row v-for="clothing in this.orderedClothes" :key="clothing.id">
        <md-table-cell>{{ clothing.count}}x {{ clothing.type}}</md-table-cell>
        <md-table-cell>{{ clothing.count * clothing.price }} CHF</md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-cell>Total</md-table-cell>
        <md-table-cell>{{ totalPrice }} CHF</md-table-cell>
      </md-table-row>
    </md-table>

    <p v-else>Noch keine Bestellung definiert.</p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "order-summary",
  data: () => ({
    priceTotal: null
  }),

  computed: {
    ...mapGetters({
      orderedClothes: 'order/getOrderedClothes',
    }),
    totalPrice(){
      var priceTotal = 0;
      this.orderedClothes.forEach(function(clothing){
        priceTotal = priceTotal + (clothing.price * clothing.count)
      })
      if(priceTotal !== 0){
        this.$store.dispatch("order/updateTotalPrice", priceTotal)
      }

      return priceTotal
    }
  },
}
</script>