<template>
  <div class="order md-layout-item md-size-100">
    <h2 class="md-headline">Auftrag</h2>
    <div style="text-align: center; display: flex; justify-content: space-between">

      <span v-for="clothing in allClothes" :key="clothing.id">
        <md-dialog-prompt
            :md-active="isActive === true"
            @input="onDialogInput"
            :value="activeClothes.count"
            :md-input-id="activeClothes.id"
            :md-content="dialogContent"
            md-title="Wie viele gleiche Stücke geben Sie auf?"
            :md-input-placeholder="'Stückzahl angeben. ' + '(' + activeClothes.price + ' CHF pro Stück)'"
            md-cancel-text="ABBRECHEN"
            md-confirm-text="OK"
            @md-confirm="submitClothes(activeClothes.id)"
            @md-cancel="setActiveClothing(clothing); isActive = false;"
        />
        <div class="overly-container" style="text-align: center">
          <img @click="isActive = true; activeClothes = clothing ;dialogContent = ''" :src="clothing.icon" class="overlay-image">
          <div v-if="clothing.count" @click="isActive = true; activeClothes = clothing ;dialogContent = ''"  class="overlay">
            <span class="overlay-text">{{ clothing.count }}x</span>
          </div>
        </div>
    </span>
  </div>

    <md-field>
      <label>Bemerkungen zum Auftrag</label>
      <md-textarea v-model="order.comment" @blur="addComment"></md-textarea>
    </md-field>

    <p class="md-caption" style="font-size: larger">
      Bitte beachten Sie, dass Sie nur Waschaufträge über diesen Weg eingeben können.
      Sollten Reparaturen auftreten, bitten wir Sie bei uns im Laden vorbeizuschauen.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "wash-order",
  data: () => ({
    activeClothes: {},
    isActive: false,
    value: null,
    count: null,
    dialogContent: '',
    order: {
      comment: ''
    },
  }),

  methods: {
    submitClothes(clothingID){
      if(this.activeClothes.count === '' || this.activeClothes.count === undefined || this.activeClothes.count === "0"){
        this.$store.dispatch("order/removeClothing", this.selectedClothing(clothingID))
        this.activeClothes.count = ''
        this.isActive = false
        this.dialogContent = ''
      }
      else if(this.activeClothes.count && this.checkNumber(this.activeClothes.count)){
        this.$store.dispatch("order/updateClothes", this.selectedClothing(clothingID))
        this.isActive = false
        this.dialogContent = ''
      }
      this.$store.dispatch("order/validateInputs")
    },
    addComment(){
      if(this.order.comment !== ""){
        this.$store.dispatch("order/updateComment", this.order.comment)
      }
    },
    setActiveClothing(clothing){
      let selectedClothing = null
      selectedClothing = this.getOrderedClothingById(clothing.id)

      if(selectedClothing){
        this.activeClothes = Object.assign({}, selectedClothing)
      } else{
        this.activeClothes = clothing
        this.activeClothes.count = ''
      }
    },
    checkNumber(data){
      if(isNaN(data)){
        return false
      }else{
        return true
      }
    },
    onDialogInput(e) {
      this.activeClothes.count = e
      if(!this.checkNumber(e)){
        this.dialogContent = "Tragen Sie einen gültigen Wert ein!"
      }
    },
  },

  computed: {
    ...mapGetters({
      allClothes: 'clothes/allClothes',
      selectedClothing: 'clothes/getClothing',
      getOrderedClothingById: 'order/getOrderedClothingById'
    }),
  },
}
</script>

<style lang="scss">
  .overly-container {
    position: relative;
    width: 29vw;
    height: 29vw;
    cursor: pointer;
  }
  .overlay-image {
    display: block;
    padding: 2vw;
    z-index: 2;
  }
  .overlay {
    position: absolute;
    top: 0;
    background: rgba(171,16,60, 0.6);
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    font-size: 18px;
    padding: 20px;
    text-align: center;
  }
  .overlay-text {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
</style>