import * as fb from "../../firebase";

/* ========== STATE ========== */
const state = {
    company: {},
    companyLogo: '',
};

/* ========== GETTERS ========== */
const getters = {
    company: state => {
        return state.company
    },
    companyLogo: state => {
        return state.companyLogo
    }
};

/* ========== ACTIONS ========== */
const actions = {
    async getCompany({ commit }, companyId) {
        const company = await fb.companiesCollection.doc(companyId).get()
        commit('SET_COMPANY', company.data())

        const logoRef = fb.storage.ref().child('logo/' + company.data().logo);
        logoRef.getDownloadURL()
            .then((response) => {
                commit('SET_COMPANY_LOGO', response)
            })
            .catch((error) => {
                switch (error.code) {
                    case 'storage/object-not-found':
                        this.errLogo = "Logo konnte nicht gefunden werden";
                        break;
                    case 'storage/unauthorized':
                        this.errLogo = "Fehlende Berechtigung um das Logo zu laden";
                        break;
                    case 'storage/canceled':
                        this.errLogo = "Der Benutzer hat den Upload abgebrochen";
                        break;
                    case 'storage/unknown':
                        this.errLogo = "Ein unerwarteter Fehler ist aufgetreten";
                        break;
                }
            })
    },
};

/* ========== MUTATIONS ========== */
const mutations = {
    SET_COMPANY(state, companyData) {
        state.company = companyData
    },
    SET_COMPANY_LOGO(state, companyLogo) {
        state.companyLogo = companyLogo
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
