import Vue from 'vue'
import VueRouter from 'vue-router'
import Order from '@/views/Order'
import Home from '@/views/Home'
import OrderConfirmation from "@/views/OrderConfirmation";
import Administration from "@/views/Administration";
import Protected from "@/views/Protected";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/new/:companyId',
    name: 'Order',
    component: Order,
    props: true
  },
  {
    path: '/confirm/:companyId',
    name: 'OrderConfirmation',
    component: OrderConfirmation,
    props: true
  },
  {
    path: '/admin',
    name: 'Administration',
    component: Administration,
  },
  {
    path: '/login',
    name: 'Protected',
    component: Protected
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
