<template>
  <div class="page-container">
    <md-app md-mode="reveal">

      <!-- App Toolbar -->
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <h1 class="md-title">{{ company.name }} - Auftrag aufgeben</h1>
      </md-app-toolbar>

      <!-- App Drawer -->
      <md-app-drawer :md-active.sync="menuVisible">
        <md-toolbar class="md-transparent" md-elevation="0">Navigation</md-toolbar>
        <md-list>
          <md-list-item>
            <md-icon>confirmation_number</md-icon>
            <p class="md-list-item-text">
              <router-link to="/new/YsCVjhVqaww3PLKDxSQN">Zur App - Sarkis</router-link>
            </p>
          </md-list-item>
          <md-list-item>
            <md-icon>shopping_bag</md-icon>
            <p class="md-list-item-text" style="text-decoration: none">
              <router-link to="/new/spE23R8GeleMlGYsGwH4">Zur App - Test</router-link>
            </p>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <!-- App Content -->
      <md-app-content>
        <div class="md-layout md-gutter" v-if="err === ''">
          <div class="info md-layout-item md-size-100">
            <div style="text-align: center">
              <img v-if="errLogo === ''" :src="companyLogo" style="max-width: 50vw">
              <p v-else>{{ errLogo }}</p>
            </div>
            <br>
            <p class="md-caption" style="font-size: larger">Mit diesem Formular können Sie einen Reinigungsauftrag aufgeben.</p>
          </div>

          <!-- Scann Item - Component -->
          <scan-item/>

          <!-- Personal Information - Component -->
          <personal-information/>

          <!-- Order - Component -->
          <order-wash/>

          <!-- Order Summery - Component -->
          <order-summary/>

          <!-- Payment Options - Component -->
          <payment-options/>

          <!-- Button - Send Order -->
            <md-button :disabled="!validationInputs" @click="uploadOrder" type="submit" class="md-raised md-primary" style="text-transform: uppercase;">Bestellung aufgeben</md-button>
        </div>

        <div class="md-layout md-gutter" v-else>
          <p class="md-headline">Error</p>
          <p>Es ist ein Fehler aufgetreten, versuchen Sie es später erneut.</p>
          <md-snackbar md-position="center" :md-active.sync="errState" md-persistent>
            <span>{{ this.err }}</span>
            <md-button class="md-primary" @click="err = ''">Schliessen</md-button>
          </md-snackbar>
        </div>

      </md-app-content>

    </md-app>
  </div>
</template>

<script>
  import {
    ScanItem,
    PersonalInformation,
    OrderWash,
    OrderSummary,
    PaymentOptions,
  } from "@/components/index"
  import {mapGetters} from "vuex";

  export default {
    name: "Order",
    components: {
      PaymentOptions,
      OrderSummary,
      OrderWash,
      PersonalInformation,
      ScanItem,
    },

    props: {
      companyId: {
        type: String
      },
    },

    data: () => ({
      menuVisible: false,
      err: '',
      errState: false,
      errLogo: '',
      loading: true,
      submitStatus: null,
      order: {
        client: {
          name: '',
          email: '',
          phoneNr: ''
        },
        contract: {
          comments: '',
          clothes: []
        },
        company: {
          id: ''
        },
        payment: {
          method: '',
          cardNumber: '',
          expireDate: '',
          cvv: null
        },
      }
    }),

    mounted() {
       this.$store.dispatch('clothes/getClothes')
          .then(() => {
            console.log("<<clothes/getClothes>> dispatched");
            this.loading = false;

          }).catch(() => {
            this.loading = false;
            this.err = "Verbindung zum Server fehlgeschlagen."
            this.errState = true
      })
      this.$store.dispatch('companies/getCompany', this.companyId)
          .then(() => {
            console.log("<<companies/getCompany>> dispatched");
            this.loading = false;

          }).catch(() => {
            this.loading = false;
            this.err = "Verbindung zum Server fehlgeschlagen."
            this.errState = true
      })
    },

    computed : {
      ...mapGetters({
        company: 'companies/company',
        companyLogo: 'companies/companyLogo',
        validationInputs: 'order/getValidationInputs'
      }),
    },
    methods: {
      uploadOrder(){
        this.$store.dispatch("order/uploadOrder", this.companyId)
      },
    }
  }
</script>

<style lang="scss">
$primary-color: #AB103C;
$secondary-color: lighten($primary-color, 25%);

@import "~vue-material/dist/theme/engine";
@include md-register-theme("default", (
    primary: $primary-color,
    accent: $secondary-color
));
@import "~vue-material/dist/theme/all";

.md-overlay {
  background: $primary-color !important;
  opacity: 0.3 !important;
}
</style>