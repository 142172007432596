<template>
  <div class="scan md-layout-item md-medium-size-50 md-small-size-100">
    <h2 class="md-headline">Wäsche verpacken</h2>
    <ol style="font-size: larger">
      <li>
        <p>Bitte verpacken Sie die Wäsche gemäss der Beschreibung auf der Box in einen Plastiksack.</p>
      </li>
      <li>
        <p>Kleben Sie einen QR-Code-Sticker auf den Sack.</p>
      </li>
      <li v-if="!error">
        <p>Scannen Sie den QR-Code:</p>
        <md-button v-if="showCamera !== true && scannedId === ''" @click="scannQrCode" class="md-raised md-primary" style="text-transform: uppercase;">Scannen</md-button>
        <div v-if="scannedId">
          <p>Danke! (Nummer: {{ scannedId }})</p>
          <md-button @click="scannQrCode(); scannedId = ''" class="md-primary" style="text-transform: uppercase;">Erneut Scannen</md-button>
        </div>
      </li>
      <li v-else>
        <p>Nummer von Hand eintragen.</p>
        <md-field>
          <label>Nummer auf dem QR-Code</label>
          <md-input v-model="scannedId" type="text" @blur="addScannedId"></md-input>
        </md-field>
      </li>
    </ol>

    <div v-if="showCamera">
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="!destroyed">
      </qrcode-stream>
    </div>
    <md-snackbar :md-position="position" :md-duration="Infinity" :md-active.sync="showSnackbar" md-persistent style="max-height: 140px;">
      <span>
        <p>{{error}}</p>
        <md-button v-if="showCamera !== true && scannedId === ''" @click="scannQrCode(); error = ''; showSnackbar = false" class="md-raised md-primary" style="text-transform: uppercase;"><md-icon>cached</md-icon> ERNEUT VERSUCHEN</md-button>
      </span>

      <md-button class="md-primary" @click="showSnackbar = false"><md-icon>close</md-icon></md-button>
    </md-snackbar>

  </div>
</template>

<script>

export default {
  name: "scan-item",

  data: () => ({
    scannedId: '',
    loading: true,
    destroyed: false,
    showCamera: false,
    camera: 'auto',
    error: '',
    showSnackbar: false,
    position: 'center',
    isInfinity: true
  }),

  methods: {
    async scannQrCode () {
      this.camera = 'auto'
      this.destroyed = true
      await this.$nextTick()
      this.destroyed = false
      this.showCamera = true
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        this.camera = 'off'
        this.showCamera = false
        this.showSnackbar = true
        if (error.name === 'NotAllowedError') {
          this.error = "Zugriff auf die Kamera wurde verweigert."
        } else if (error.name === 'NotFoundError') {
          this.error = " Keine Kamera gefunden."
        } else if (error.name === 'NotSupportedError') {
          this.error = "Sicherer Kontext erforderlich (HTTPS, localhost)."
        } else if (error.name === 'NotReadableError') {
          this.error = "Wird die Kamera für etwas anderes bereits verwendet?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Installierte Kameras sind nicht geeignet."
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Stream-API wird in diesem Browser nicht unterstützt."
        }
      }
    },

    async onDecode (content) {
      this.scannedId = content
      if(this.scannedId !== "") {
        this.$store.dispatch("order/updateCompany", this.scannedId)
        this.$store.dispatch("order/validateInputs")
      } else {
        this.error = "Ein unbestimmter Fehler ist aufgetreten."
      }
      this.camera = 'off'
      this.showCamera = false
    },

    addScannedId() {
      this.$store.dispatch("order/updateCompany", this.scannedId)
      this.$store.dispatch("order/validateInputs")
    }
  }
}
</script>