import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  MdApp,
  MdDrawer,
  MdToolbar,
  MdList,
  MdIcon,
  MdButton,
  MdContent,
  MdField,
  MdTable,
  MdCard,
  MdRadio,
  MdDialog,
  MdDialogPrompt,
  MdSnackbar,
  MdRipple,
  MdSwitch,
  MdCheckbox

} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import VueQrcodeReader from "vue-qrcode-reader";
import Vuelidate from "vuelidate";
import VueCardFormat from 'vue-credit-card-validation';
import VueStorageHelper from 'vue-storage-helper'

Vue.use(MdApp);
Vue.use(MdDrawer);
Vue.use(MdToolbar);
Vue.use(MdList);
Vue.use(MdIcon);
Vue.use(MdButton);
Vue.use(MdContent);
Vue.use(MdField);
Vue.use(MdTable);
Vue.use(MdCard);
Vue.use(MdRadio);
Vue.use(MdDialogPrompt);
Vue.use(MdDialog);
Vue.use(MdSnackbar);
Vue.use(MdRipple);
Vue.use(MdSwitch);
Vue.use(MdCheckbox);

Vue.use(VueStorageHelper, { debug: true })

Vue.use(VueQrcodeReader);
Vue.use(Vuelidate);
Vue.use(VueCardFormat);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
