import Vue from 'vue'
import Vuex from 'vuex'

// importing modules
import admin from "./modules/admin"
import order from "./modules/order";
import clothes from "./modules/clothes";
import companies from "./modules/companies";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    admin,
    clothes,
    order,
    companies
  },
  strict: debug
})