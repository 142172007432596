<template>
  <div class="page-container" v-if="loaded">
    <md-app md-mode="reveal">
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <h1 class="md-title">Übersicht der Aufträge</h1>
      </md-app-toolbar>

      <!-- App-Drawer - Navigation -->
      <md-app-drawer :md-active.sync="menuVisible">
        <md-toolbar class="md-transparent" md-elevation="0">Navigation</md-toolbar>
        <md-list>
          <md-list-item>
            <md-icon>confirmation_number</md-icon>
            <p class="md-list-item-text">
              <router-link to="/new/YsCVjhVqaww3PLKDxSQN">Zur App - Sarkis</router-link>
            </p>
          </md-list-item>
          <md-list-item>
            <md-icon>shopping_bag</md-icon>
            <p class="md-list-item-text" style="text-decoration: none">
              <router-link to="/new/spE23R8GeleMlGYsGwH4">Zur App - Test</router-link>
            </p>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <!-- App Content -->
      <md-app-content>
        <div class="md-layout md-gutter">

          <!-- Table -->
          <md-table>
            <md-table-row md-selectable="single">
              <md-table-head>Status</md-table-head>
              <md-table-head>#Bestellung</md-table-head>
              <md-table-head>Zahlungsmethode</md-table-head>
              <md-table-head>Name</md-table-head>
              <md-table-head>Email</md-table-head>
              <md-table-head>Telefon</md-table-head>
              <md-table-head>Adresse</md-table-head>
              <md-table-head>Auftrag</md-table-head>
            </md-table-row>

            <md-table-row v-for="order in allOrders" :key="order.index">
              <md-table-cell>
                <md-switch :value="order.state" @change="onStateChange(order.id, $event)">{{ order.state === true ? "Erledigt" : 'Offen'}}</md-switch>
              </md-table-cell>
              <md-table-cell>{{ order.company }}</md-table-cell>
              <md-table-cell>{{ getPaymentMethod(order.payment.method) }}</md-table-cell>
              <md-table-cell>{{ order.name }}</md-table-cell>
              <md-table-cell>{{ order.email}}</md-table-cell>
              <md-table-cell>{{ order.telefon}}</md-table-cell>
              <md-table-cell>{{getFullAdress(order.payment.address, order.payment.method) }}</md-table-cell>
              <md-table-cell>
                <li v-for="clothing in order.clothes" :key="clothing.index">
                  {{ showClothing(clothing) }}
                </li>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <br>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "Administration",
  data: () => ({
    menuVisible: false,
    activeOrderState: false,
    loaded: false
  }),
  mounted() {
    // Password query
    if (this.$route.query.password) {
      localStorage.password = 'HeloWorld'
      console.log('Logged in')
    } else if(localStorage.password && localStorage.password === 'HeloWorld') {
      console.log('Logged in')
    }else {
      this.$router.push('/login')
    }
    this.$store.dispatch('admin/getOrders')
    .then(() => {
      console.log("<<admin/getOrders>> dispatched");
      this.$store.dispatch('admin/getClothes')
          .then(() => {
            console.log("<<admin/getClothes>> dispatched");
            this.loaded = true
          }).catch(() => {
        console.error("Error: <<admin/getClothes>> ")
      })
    }).catch(() => {
      console.error("Error: <<admin/getOrders>> ")
    })
  },

  methods: {
    getFullAdress(address, method){
      if (method === 'monthly_invoice'){
        const place = address[0].place;
        const street = address[0].street;
        const postcode = address[0].postcode;
        return postcode + ' ' + place + ', ' + street;
      } else {
        return 'Kreditkarte - keine Adresse angegeben'
      }

    },
    getPaymentMethod(method){
      if (method === 'monthly_invoice'){
        return 'Rechnung'
      } else if( method === 'creditcard'){
        return 'Kreditkarte'
      } else {
        return 'undefined'
      }
    },
    showClothing(clothing){
      const clothesType = this.getClothing(clothing.clothesId);
      if (clothesType) {
        const returnMsg = clothing.count + 'x -' + clothesType.type;
        return returnMsg
      } else {
        //console.log('error')
        //return clothing.count + 'x -' + 'error'
      }
    },
    onStateChange(orderId, status) {
      status ? status = false : status = true
      this.$store.dispatch("admin/updateOrderStatus", { orderId, status })
    },
    getCorrectState(status){
      if (status){
        return 'on'
      } else {
        return 'off'
      }
    }
  },
  computed: {
    ...mapGetters({
      allOrders: 'admin/getOrders',
      getClothing: 'admin/getClothing'
    }),
  },
}
</script>

<style scoped>
  .md-table-cell {
    min-width: 12.5%;
  }
</style>